import { eventCallbackConstants } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";

const triggers = [
  {
    pageSlug: "heartfulness-green",
    gtags: [
      {
        event: eventCallbackConstants.loadDonateSuccessPage,
        awConversionId: "AW-881209313/AzHHCKi4y9YBEOHfmKQD",
      },
    ],
  },
  {
    pageSlug: ["donation-general-fund", "donation-general-fund-gatsby"],
    gtags: [
      {
        event: eventCallbackConstants.loadDonateSuccessPage,
        awConversionId: [
          "AW-854824176/VxEhCLfG8PEBEPCpzpcD",
          "AW-881209313/0LVCCOHRn_UBEOHfmKQD",
        ],
      },
    ],
  },
  {
    pageSlug: "covid-relief-fund",
    gtags: [
      {
        event: eventCallbackConstants.loadDonateSuccessPage,
        awConversionId: "AW-854824176/nbezCJ7wupICEPCpzpcD",
      },
    ],
  },
];

export default triggers;
