// import React, { useCallback } from "react";
// import { graphql } from "gatsby";
// import { replace } from "@wordpress/shortcode";
// import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { isArray } from "lodash";
// import PropTypes from "prop-types";
// import DonationLayout from "../layout/DonationLayout";
// import PressRelease from "../components/PressRelease";
// import SocialIcon from "../components/SocialIcon";
import triggers from "../../data/awConversionId";

let isGtagSet = false;

// function donationTag(val) {
//   const n = { ...val.attrs.named };
//   n.btntext = n.btntext ? n.btntext.replace(/_/g, " ") : "Donate Now";
//   n.titletext = n.titletext
//     ? n.titletext.replace(/_/g, " ")
//     : "Heartfulness General Fund";
//   n.btnstyle = n.packagebtn
//     ? "margin:2px;margin:0;width:auto;border-radius:25px;padding-left:25px;padding-right:25px;font-size:16px"
//     : "margin:2px;margin-left:auto;margin-right:auto;width:auto;border-radius:25px;padding-left:25px;padding-right:25px;";

//   const {
//     donationid = 9,
//     amount = "",
//     rate = "",
//     showinput = "",
//     projectid = "",
//     btntext = "Donate Now",
//     btnbg = "",
//     btnstyle = "",
//     colortext,
//     colorprimary,
//     colorsecondary,
//     colorbackground,
//     colormuted,
//     colorhighlight,
//     titletext,
//     enablesigninoption = "1",
//   } = n;
//   return `<gatsby_donation amount='${amount}' btntext='${btntext}' donationid='${donationid}'
//   projectid='${projectid}' btnbg='${btnbg}' btnstyle='${btnstyle}' colortext='${colortext}' colorprimary='${colorprimary}'
//   colorsecondary='${colorsecondary}' colorbackground='${colorbackground}' colormuted='${colormuted}'
//   colorhighlight='${colorhighlight}' titletext='${titletext}' rate='${rate}' showinput='${showinput}' enablesigninoption='${enablesigninoption}' />`;
// }
// function getPdf(val, media) {
//   let pdfSrc = media.find((n) => {
//     return n.node.source_url.includes(val.attrs.named.link);
//   });
//   pdfSrc = pdfSrc ? pdfSrc.node.localFile.publicURL : val.attrs.named.link;
//   return `<a href=${pdfSrc} target="_blank" >
//   ${val.attrs.named.linktext.replace(
//     /_/g,
//     " "
//   )}<img src=${val.attrs.named.linktextimg.replace(
//     /\/+$/,
//     ""
//   )} alt="no-img"  className="img-fluid" /></a>`;
// }
// export const replaceTags = (content, media) => {
//   let newContent = replace("gatsby_donation", content, (val) =>
//     donationTag(val)
//   );
//   newContent = replace(
//     "donation_package",
//     newContent,
//     (val) => `<donation_package type=${val.attrs.named.type} />`
//   );
//   newContent = replace("press_release", newContent, () => `<press_release />`);
//   newContent = replace("pdf_link", newContent, (val) => getPdf(val, media));
//   newContent = replace(
//     "social_icon",
//     newContent,
//     (val) => `<social_icon type=${val.attrs.named.type} />`
//   );
//   return newContent;
// };

// const PageContent = React.memo(PageContentNonMemoized);

const gTag = (awConversionId) => {
  if (
    !isGtagSet &&
    typeof window !== "undefined" &&
    "gtag" in window &&
    typeof window.gtag === "function" &&
    awConversionId
  ) {
    isGtagSet = true;
    if (typeof awConversionId === "string") {
      window.gtag("event", "conversion", {
        send_to: awConversionId,
        transaction_id: "",
      });
    } else if (isArray(awConversionId)) {
      awConversionId.forEach((a) => {
        window.gtag("event", "conversion", {
          send_to: a,
          transaction_id: "",
        });
      });
    }
  }
};

// //
// // /* sample */
// // const triggers = [
// //   {
// //       pageSlug: "heartfulness-green",
// //       gtags: [
// //           { event: eventCallbackConstants.clickDonateBtn, awConversionId: 'AW-881209313/AzHHCKi4y9YBEOHfmKQD' }
// //       ]
// //   },
// //   {
// //       pageSlug: ["donation-general-fund", "donation-general-fund-gatsby"],
// //       gtags: [
// //           { event: eventCallbackConstants.clickDonateBtn, awConversionId: 'AW-854824176/VxEhCLfG8PEBEPCpzpcD' }
// //       ]
// //   },
// // ]

export const eventCallbackTriggers = (pageSlug, eventName) => {
  triggers
    .filter((x) =>
      isArray(x.pageSlug)
        ? x.pageSlug.includes(pageSlug)
        : x.pageSlug === pageSlug
    )
    .forEach((x) => {
      const { gtags } = x;
      if (gtags) {
        gtags
          .filter(
            ({ event }) =>
              event &&
              (isArray(event) ? event.includes(eventName) : event === eventName)
          )
          .forEach(({ awConversionId }) => {
            gTag(awConversionId);
          });
      }
    });
};

// const WPPageDetail = ({ data }) => {
//   const { wordpressPage, allWordpressWpMedia } = data;
//   const { seo_title: title } = wordpressPage.acf;
//   const { slug } = wordpressPage;
//   const mediaEdges = allWordpressWpMedia.edges;

//   const pageContent = replaceTags(wordpressPage.content, mediaEdges);

//   const pressreleaseContent = wordpressPage.acf.press_release_content;
//   const packageData = wordpressPage.acf.package_layout;
//   const eventCallback = useCallback(
//     (event) => eventCallbackTriggers(slug, event),
//     [slug]
//   );

//   return (
//     <DonationLayout seoData={{ title }}>
//       <PageContent
//         eventCallback={eventCallback}
//         mediaEdges={mediaEdges}
//         pageContent={pageContent}
//         pressreleaseContent={pressreleaseContent}
//         PressRelease={PressRelease}
//         packageData={packageData}
//         SocialIcon={SocialIcon}
//       />
//     </DonationLayout>
//   );
// };

// WPPageDetail.propTypes = {
//   data: PropTypes.shape({
//     wordpressPage: PropTypes.shape({
//       content: PropTypes.string.isRequired,
//       acf: PropTypes.shape({
//         package_layout: PropTypes.oneOfType([
//           PropTypes.string,
//           PropTypes.shape({}),
//         ]),
//         press_release_content: PropTypes.oneOfType([
//           PropTypes.string,
//           PropTypes.shape({}),
//         ]),
//         seo_title: PropTypes.string,
//       }),
//       slug: PropTypes.string,
//     }).isRequired,
//     allWordpressWpMedia: PropTypes.shape({
//       edges: PropTypes.arrayOf(PropTypes.object),
//     }).isRequired,
//   }).isRequired,
// };

// export default WPPageDetail;

// export const pageQuery = graphql`
//   query PageById($pageSlug: String!) {
//     wordpressPage(slug: { eq: $pageSlug }) {
//       slug
//       content
//       title
//       wordpress_id
//       acf {
//         press_release_content
//         seo_title
//         package_layout
//       }
//     }
//     allWordpressWpMedia(filter: { media_type: { eq: "image" } }) {
//       edges {
//         node {
//           source_url
//           localFile {
//             publicURL
//             name
//           }
//         }
//       }
//     }
//   }
// `;
