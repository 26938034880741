export const greenKanhaPackageData = {
  treePackage: {
    rate: "1000",
    predefinedPackage: {
      label: "Select Package of trees",
      data: [
        {
          qty: 5,
          title: "Package of 5 trees - INR 5,000.00: USD 78 EU 66.5",
          desc: "1)Neem 2)Palash 3) Tree of Heaven 4) Champaka 5) Pride of India",
        },
        {
          qty: 10,
          title: "Package of 10 trees - INR 10,000.00: USD 156 EU 133",
          desc: "1) Malabar Neem 2) Bilwa: Golden Apple tree  3) Sandalwood 4) Red sanders 5) Golden Shower 6) Night Jasmine 7) Peepal 8) Shisham 9) Indian Tulip 10) Haritaki ",
        },
        {
          qty: 15,
          title: "Package of 15 trees - INR 15,000.00: USD 234 EU 199.5",
          desc: "1) 3 Neem 2) 3 Peepal 3) 3 Pride of India 4) 3 Champaka 5) 3 Ashoka ",
        },
        {
          qty: 25,
          title: "Package of 25 trees - INR 25,000.00: USD 390 EU 332.5",
          desc: "1) 5 Neem 2) 5 Indian Rosewood 3) 5 Red sanders 4) 5 Tree of Heaven 5) 5 Sandalwood ",
        },
      ],
    },
    customPackage: {
      inputLabel: "Quantity",
      extraLabel: "Trees",
      extra: [
        "Peepal / Raavi Chetlu/ Arasu Mara/ Arali Mara",
        "Neem / Vepa Chetlu",
        "Karanj / Kanuga/ Pungam",
        "Indian Rosewood",
        "Shisham/Sissoo",
        "Palash / Flame of the Forest/ Mothaka chetlu",
        "Saptaparni",
        "Alexandrian Laurel",
        "The Tree of Heaven",
        "Ashoka/ Sorrowless Tree",
        "Champaka / Sampangi/ Sampige",
        "Bakula/ Indian Medlar",
        "Paarijaath/ Night Jasmine",
        "Indian Tulip",
        "Indian Laburnum",
        "Pride of India / Queen’s Flower",
        "Wood Apple",
        "Kachnar / Camel foot Tree",
        "Mahua",
        "Malabar Neem/ Melia dubia",
        "Kadamba",
        "Arjuna",
        "Bahara",
        "Haritaki",
        "Bilwa: Golden Apple Tree",
        "Putranjiva/Indian Amulet Tree",
        "Kokko / East Indian Walnut",
        "Kejri",
        "Sandalwood",
        "Red Sanders / Yerra Sandalwood/ Rakta Sandalwood",
        "Gamhar / Shivane",
        "Wisteria",
        "Cinnamon",
        "Indian elm tree",
        "Soap nut",
        "Tamarind",
        "Jambol",
        "Mango",
        "Grapes",
        "Chikoo",
        "Elaichi kela",
        "Dates",
        "Teak",
      ],
    },
  },
};

export const helpPackageData = {
  treePackage: {
    rate: "50",
    predefinedPackage: {
      label: "Select the Packages",
      data: [
        {
          qty: 100,
          title: "100 Students - INR 5,000.00",
          desc: "",
        },
        {
          qty: 200,
          title: "200 Students - INR 10,000.00",
          desc: "",
        },
        {
          qty: 500,
          title: "500 Students - INR 25,000.00",
          desc: "",
        },
      ],
    },
    customPackage: {
      inputLabel: "Enter the Number of Students",
    },
  },
};

export const heartfulnessUSA = {
  treePackage: {
    rate: "1",
    currency: "USD",
    predefinedPackage: {
      defaultValue: {
        qty: 50,
        title: "$50",
        desc: " ",
      },
      containerStyle: {
        flexDirection: "row",
        gap: 3,
        justifyContent: "center",
      },
      label: " ",
      data: [
        {
          qty: 25,
          title: "$25",
          desc: " ",
        },
        {
          qty: 50,
          title: "$50",
          desc: " ",
        },
        {
          qty: 100,
          title: "$100",
          desc: " ",
        },
        {
          qty: "",
          title: "Others",
          desc: " ",
        },
      ],
    },
  },
};

export const pressReleaseContent = [
  {
    tittle: "NDTV",
    description:
      "At Kanha Shantivan, there are many stories of how people have come together to give a second lifetotrees<br /><a href='https://www.ndtv.com/telangana-news/a-telangana-hospital-that-gives-dying-trees-a-second-life-1845101' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Business Standard",
    description:
      "Spurred by the success of Green Kanha, a successful initiative for shaping the micro climate ofhundredsof acres of Kanha Shantivanam<br /><a href='https://www.business-standard.com/article/news-ani/a-mission-to-green-india-over-the-next-year-118060400556_1.html' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Outlook",
    description:
      "Kamlesh D Patel of Global Guide of Heartfulness said,”Green Kanha is a result of extraordinaryeffortsto recharge the dry and barren ecosystem<br /><a href='https://www.outlookindia.com/newsscroll/a-mission-to-green-india-over-the-next-year/1322702' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Deccan Chronicle",
    description:
      "Hyderabad city outskirts are all set to battle out pollution as over 500 saplings were planted atKanhaShantivanam on the Diwali eve for a green celebrations.<br /><a href='https://www.deccanchronicle.com/nation/current-affairs/191017/500-saplings-planted-on-hyderabad-outskirts.html' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Times of India",
    description:
      "More than 300 trees that were marked for felling in different parts of Hyderabad<br /><a href='https://timesofindia.indiatimes.com/city/hyderabad/over-300-trees-uprooted-for-road-widening-get-new-lease-of-life-in-kanha-shantivanam/articleshow/60717887.cms' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "New Indian Express",
    description:
      "Heartfulness Institute at Kanha Shantivanam, near Shamshabad, said that it has successfullycompleted amilestone of translocating over 1,000 trees<br /><a href='https://www.newindianexpress.com/cities/hyderabad/2018/apr/30/bringing-in-the-greens-from-all-quarters-1808439.html' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Telangana Today",
    description:
      "The Green Kanha initiative is to enrich the natural environment and lessen the destruction causedbyhuman activities<br /><a href='https://telanganatoday.com/president-kovind-plants-sapling-at-heartfulness-institute-in-hyderabad' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "The Quint",
    description:
      "The Heartfulness Institute on Monday announced plans to develop a mini-rainforest over 25 acres oflandat Kanha Shantivanam, its global headquarters,<br /><a href='https://www.thequint.com/hotwire-text/heartfulness-institute-to-develop-rainforest-near-hyderabad' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Hans India",
    description:
      "Heartfulness Institute located at Kanha Shantivanam in Hyderabad has successfully completed amilestoneof translocating over 1,000 trees in just over a year<br /><a href='https://www.thehansindia.com/posts/index/Hyderabad-Tab/2018-05-04/A-milestone-achieved-in-relocating-1k-trees/378651' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "ANI",
    description:
      "Spurred by the success of Green Kanha, a successful initiative for shaping the micro climate ofhundredsof acres of Kanha Shantivanam in Hyderabad<br /><a href='https://www.aninews.in/news/national/general-news/a-mission-to-green-india-over-the-next-year201806041608010001/' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "Daily Hunt",
    description:
      "Heartfulness Institute (www.heartfulness.org), located at Kanha Shantivanam, Hyderabad, announcedthatit has successfully completed a milestone of translocating over 1,000 trees<br /><a href='https://m.dailyhunt.in/news/india/english/india+education+diary-epaper-indedu/heartfulness+institute+achieves+the+milestone+of+successfully+relocating+and+replanting+over+1+000+trees-newsid-86996682' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "India CSR",
    description:
      "Continuing its focus to extend the green cover, Heartfulness Institute, located at KanhaShantivanam,Hyderabad, today planted 1000 plus trees<br /><a href='https://indiacsr.in/environment-sustainability-heartfulness-institute-plants-30000-trees target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "UNI",
    description:
      "Heartfulness Institute achieves milestone of successfully replanting over 1,000 trees<br /><a href='http://www.uniindia.com/heartfulness-institute-achieves-milestone-of-successfully-replanting-over-1-000-trees/states/news/1215842.html' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
  {
    tittle: "India Education Dairy",
    description:
      "Hyderabad, announced that it has successfully completed a milestone of translocating over 1,000trees injust over a year, with the replantation of the latest batch of 120 trees– coconut trees <br /><a href='https://indiaeducationdiary.in/heartfulness-institute-achieves-milestone-successfully-relocating-replanting-1000-trees/' target='blank' rel='noopener noreferrer'>Read More</a>",
  },
];
