/** @jsx jsx */
import { jsx } from "theme-ui";

import { Layout, Content, Section } from "maker-ui";

import PropTypes from "prop-types";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import HeaderComp from "../components/Header";
import FooterComp from "../components/Footer";
import Seo from "../components/seo";
import SiteAccessRestriction from "../components/SiteAccessRestriction";

const DonationLayout = ({ children, seoData, isGeneralFund }) => {
  const isMObileSite = seoData.title.toLowerCase().includes("mobile");

  return (
    <Layout theme={theme} options={options}>
      <Seo title={seoData.title} />
      {!isMObileSite && <HeaderComp />}
      <Content>
        <Section>
          <SiteAccessRestriction />
          {children}
        </Section>
      </Content>
      {!isMObileSite && <FooterComp isGeneralFund={isGeneralFund} />}
    </Layout>
  );
};

DonationLayout.propTypes = {
  children: PropTypes.element.isRequired,
  seoData: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  isGeneralFund: PropTypes.bool.isRequired,
};

export default DonationLayout;
