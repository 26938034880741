/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";
import { heartfulnessUSA } from "../../data/donation-packages";

const GeneralDonationHI = () => {
  const packageData = heartfulnessUSA;

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation_HI,_USA", event),
    []
  );

  const treePackage =
    '<div class="container"><div class="tree_package"><donation_package type="predefined"/></div></div><div class="container"><div id="tree_package" class="col-12"><gatsby_donation donationId =  "17" btntext = "Donate Now" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation - HI, USA" /></div>';

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Heartfulness Donations",
        }}
      >
        <Box
          sx={{
            paddingTop: "30px",
            alignItems: "center",
            minHeight: "620px",
            maxHeight: "620px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
          }}
        >
          <Box sx={{ paddingBottom: "30px", justifyContent: "center" }}>
            <h2
              sx={{
                color: "White",
                marginTop: "20px",
                fontWeight: "500",
                lineHeight: "1.2",
                fontSize: "55px",
                marginBottom: "1rem",
                textAlign: "center!important",
              }}
            >
              Heartfulness USA Donations
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  backgroundColor: "rgb(77, 143, 21)",
                  padding: "6px 20px",
                  borderRadius: "25px",
                  color: "white",
                  border: "0px",
                  width: "140px",
                  height: "38px",
                }}
              >
                <a
                  href="#contribute"
                  sx={{
                    color: "white",
                    ":hover": {
                      color: "white",
                      textDecoration: "none",
                    },
                  }}
                >
                  Contribute{" "}
                </a>
              </Box>
            </div>
          </Box>
        </Box>
        <Grid style={{ padding: "20px 15px" }}>
          <Box>
            <div className="container" sx={{ padding: "30px 0" }}>
              <div className="col-12">
                <h1
                  sx={{
                    fontSize: "1.5em",
                    color: "#2d3550",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  General donations to Heartfulness Institute, USA
                </h1>
                <p style={{ textAlign: "justify" }}>
                  Donations of any size make it possible for us to change lives
                  by teaching Heartfulness techniques. Our dedicated volunteers,
                  many of whom are professionals, donate their time and talents
                  and cover their own expenses. Your donation will support our
                  programs in schools, universities, colleges, corporations,
                  hospitals, and communities worldwide. 
                </p>
                <p style={{ textAlign: "justify" }}>
                  All donations made are voluntary and are suggested to be made
                  to the general fund of Heartfulness Institute. General funds
                  are used for projects in areas of COVID 19 CARE, Environment,
                  Animal shelter, Green initiatives, Positive lifestyle, and
                  Sustainability initiatives, among others.
                </p>
                <div id="contribute" className="container">
                  <div className="tree_package">
                    <PageContent
                      eventCallback={eventCallback}
                      pageContent={treePackage}
                      packageData={packageData}
                    />
                  </div>
                </div>
                <div className="container">
                  <div
                    sx={{
                      textAlign: "center",
                      margin: "auto",
                      padding: "25px 15px",
                      boxShadow: "0 2px 4px 0 rgba(170,170,170,0.5)",
                      border: "solid 1px rgba(194,198,206,0.5)",
                    }}
                  >
                    <p>
                      For matching double donation from your organisation,
                      please send email request to{" "}
                      <a
                        sx={{ wordBreak: "break-word", marginTop: "12px" }}
                        href="mailto:us.treasurer@heartfulnessinstitute.org?Subject=Matching Double Donation (https://donations.heartfulness.org/heartfulness-institute-donation-usa/)"
                        target="_top"
                        rel="noopener noreferrer"
                      >
                        us.treasurer@heartfulnessinstitute.org
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default GeneralDonationHI;
